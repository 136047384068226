@import '../../scss/config';

.project-page {
  .add-btn {
    cursor: pointer;
    padding: 12px 32px;
    height: 42px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 32px;
    margin-left: 33px;

    .btn-text {
      margin-top: -4px;
    }
  }

  table tbody > tr > td {
    border-color: #ddd;
  }

  tbody {
    background-color: #f5f5f5;
  }
}

.custom-modal {
  .ant-modal-header {
    background-color: #f5f5f5;
  }

  .ant-modal-title {
    font-size: 22px;
  }
}
