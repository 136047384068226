@import '../../scss/config';

.Admin-page {
  .new-filter-wrap {
    display: flex;
    gap: 32px;
    align-items: center;
  }

  .add-serv-btn {
    cursor: pointer;
    padding: 12px 32px;
    height: 42px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    .btn-text {
      margin-top: -4px;
    }
  }

  .Admin-filter {
    margin-bottom: 32px;
  }

  .Admin-table {
    .delete-btn {
      color: rgb(230, 57, 57);
      background-color: rgb(246, 226, 226);
    }
    .edit-btn {
      color: #1fad7e;
      background-color: #e3f1e6;
    }

    .ant-table-content {
      @include custom-scrollbar;
    }
    .service-img {
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        object-position: center center;
        border-radius: 3px;
      }
    }

    .projects-link {
      text-decoration: underline;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      .projects-btn {
        color: #ebb917;
        background-color: #f5efdd;
      }
    }
    tbody {
      background-color: #f5f5f5;
      & > tr > td {
        border-color: #ddd;
      }
    }
  }
}
