.notificationcenterpage {
  .add-serv-btn {
    cursor: pointer;
    padding: 12px 32px;
    height: 42px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    
    .btn-text {
        margin-top: -4px;
      }
  }
}
