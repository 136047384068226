.AddNotificationForm {
  form {
    font-size: 1rem !important;
    .ant-divider {
      margin: 10px 0;
    }
  }
}

.editor-title {
  color: #666;
  margin-bottom: 8px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 8px;
}
.toolbarClassName {
  direction: ltr;
}
.wrapperClassName {
  margin-bottom: 12px;
  background-color: #f5f5f5;
  .editorClassName {
    padding: 0 18px;
    min-height: 180px;
  }
}

.customer_select {
  font-size: 1rem !important;
  .ant-select-selector {
    padding: 3px 0 !important;
  }
}

@media only screen and (max-width : 575px) {
    .add-notification-form{
      .client_item{
        flex-direction: row;
      }
    }
}
