#recaptcha-container {
  display: none;
}
@import './config';
@import './common';
@import './modals';

html {
  scroll-behavior: smooth;
  color: $main-black-color;

  &[lang='ar'] {
    body {
      @include bodyFont-ar;
      button {
        @include bodyFont-ar;
      }
    }
  }
  &[lang='en'] {
    body {
      @include bodyFont-ar;
      button {
        @include bodyFont-ar;
      }
    }
  }

  // &[lang="en"] {
  // 	@include bodyFont-en;
  // 	button {
  // 		@include bodyFont-en;
  // 		font-weight: bold;
  // 	}
  // }
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  @include custom-scrollbar;
}

.app {
  min-height: 100vh;
  // background-color: #f5f5f5;
  background-color: #f7f8fb;
  // display: grid;
  grid-template-rows: auto 1fr;

  // &.app-ltr {
  // 	@include bodyFont-en;
  // }

  margin: 0;
  padding: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // @import "./css/fontello.css";
  // @import "./css/ionicons.css";
}

[contenteditable='true'] {
  line-height: 1.8;
  // display: grid;
  // align-items: center;
}

.notifications-component {
  &.rtl {
    .notification__close {
      right: auto;
      left: 10px;
    }
  }
}

.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
  justify-content: center;
}
.ant-table-tbody > tr > td,
.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  text-align: center;
}

.ant-table-thead > tr > th {
  background-color: #595f6f;
  color: #fff;
  // &:first-of-type {
  //   &::before {
  //     background-color: transparent !important;
  //   }
  // }
  &::before {
    background-color: #bbb !important;
    left: 0;
    right: auto !important;
  }
}

.ant-select-rtl {
  width: 100%;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: center;
}

.popover-answers-wrap {
  display: grid;
  grid-gap: 8px;
  list-style-type: disc;
  padding-right: 22px;
  max-width: 362px;
}

.ant-popover-inner-content {
  max-width: 280px;
}

.active {
  display: block;
}
.noactive {
  display: none;
}