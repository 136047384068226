@import '../../scss/config';

.login-page {
  background-color: #fff;
  min-height: 100vh;
  padding: 42px 0;
  @include mediaLg {
    padding: 92px 0;
  }
  display: grid;
  place-items: center;
  .page-wrapper {
    display: grid;
    @include mediaXlg {
      grid-template-columns: 42% 1fr;
    }
    align-items: center;
    grid-gap: 32px;
    .img-wrap {
      background-image: url(../../assets/imgs/signin/signin.png);
      height: 500px;
      line-height: 500px;
      text-align: center;
      p {
        color: #fff;
        font-size: 20px;
        line-height: 466px;
      }
      @include mediaXlg {
        height: 100%;
        line-height: 100%;
      }
      img {
        max-width: 100%;
        width: 100%;
      }
    }

    .form-top-level-wrapper {
      display: grid;
      justify-items: center;
    }
  }
}
