.profile-page {
  margin: 22px 0;
  max-width: 800px;
  background-color: #f7f8fa;
  padding: 22px 28px;
  border-radius: 12px;
}

.form-field-wrapper-div {
  display: grid;
  grid-template-columns: 80% 20%;
  div {
    text-align: center;
    button {
      width: 80%;
      height: 40px;
      color: #fff;
      background: #1890ff;
      border-color: #1890ff;
    }
  }
}
.Active {
  display: grid;
}
.notActive {
  display: none;
}
