@import '../../scss/config';

.Selles-page {
  .new-filter-wrap {
    display: flex;
    gap: 32px;
    align-items: center;
  }

  .add-serv-btn {
    cursor: pointer;
    padding: 12px 32px;
    height: 42px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    .btn-text {
      margin-top: -4px;
    }
  }

  .Selles-filter {
    margin-bottom: 32px;
  }

  .Selles-table {
    .delete-btn {
      color: rgb(230, 57, 57);
      background-color: rgb(246, 226, 226);
    }
    .edit-btn {
      color: #1fad7e;
      background-color: #e3f1e6;
    }

    .ant-table-content {
      @include custom-scrollbar;
    }
    .service-img {
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        object-position: center center;
        border-radius: 3px;
      }
    }

    .projects-link {
      text-decoration: underline;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      .projects-btn {
        color: #ebb917;
        background-color: #f5efdd;
      }
    }
    tbody {
      background-color: #f5f5f5;
      & > tr > td {
        border-color: #ddd;
      }
    }
  }
}
.role_support{
  margin: 0 auto;
width: fit-content ;
min-width: 70px;
  height: fit-content;
  padding: 8px 10px;
color: white;
border-radius: 6px;
}
.role_support.pending{
background-color: green;

}
.role_support.open{
background-color: #40a9ff;

}
.role_support.close{
background-color: red;

}
.chat-box{
  // background-image: url('../../assets/imgs/cover.jpg');
  margin-top: 20px;
  padding: 20px 20px 0px;
  // width: 90%;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 3px 0px #c2c2c2;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;

}
.chat-box-content{
  margin-bottom: 20px;
  padding: 0px 0px 20px;
  border-bottom: 1px solid grey;
}
.message-shared-form{
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;

  input{
    height: 60px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .submit-btn{
    background-color: $main-app-color;
    height: 60px;
    border-radius: 10px;
    border: none !important;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin-top: 0px !important;
  }
}

@media (max-width:1779px) {
  .chat_message_detail{

flex-direction: column;
}
}

.err_message_chat{
  margin:  0;
  gap: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
    padding: 0;
    position: relative;
    width: 100%;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 1.5715;
    background-color: #fff;
    border: 1px solid #f6f6f6;
    transition: all 0.3s;
    height: 60px;
 padding: 6.5px 11px;
    font-size: 16px;
 border-radius: 10px;
}