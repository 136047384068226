@import '../../scss/config';

.category-page {
  div {
    font-size: 20px;
  }
  .add-btn {
    cursor: pointer;
    padding: 12px 32px;
    height: 42px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    .btn-text {
      margin-top: -4px;
    }
  }

  table tbody > tr > td {
    border-color: #ddd;
  }

  tbody {
    background-color: #f5f5f5;
  }
}
