.MerchantsStatsGrid {
margin-bottom: 30px;
  .item {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;

    p {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
    .num {
      color: #509b8f;
      font-size: 30px;
      font-weight: bold;
      display: block;
      text-align: center;
    }
    .item_icon_wrapper {
      display: flex;
      justify-content: center;
      img {
        height:50px;
        width:50px;
      }
    }
  }
}


