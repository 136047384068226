@import '../../scss/config';

.dropZoneWrapper {
  & > div {
    &:focus {
      outline: none;
    }
  }
  .dropZone {
    cursor: pointer;
    background-color: #fff;
    border: 2px dashed #bbb;
    border-radius: 4px;
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 22px;
    height: 140px;
    .placeholderText {
      display: grid;
      justify-items: center;
      i {
        font-size: 24px;
        color: #999;
      }
      p {
        margin-top: 4px;
        color: #888;
      }
    }

    &.dragZone {
      border-color: lighten($main-app-color, 20%);
      border-width: 3px;
      background-color: #eee;
    }
  }

  &.hideMe {
    display: none;
  }
}
