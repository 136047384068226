@import '../../scss/config';
.add-category-form {
  .form-field-wrapper {
    margin-top: 20px;
    .form-field {
      margin-top: 10px;
      height: 47px;
    }
    .field-label {
      color: #595f6f;
      font-weight: 700;
      margin-top: 10px;
      margin-bottom: 15px;
    }
    .error-filed {
      color: red;
    }
  }

  .form-field-submit {
    justify-content: center;
    text-align: center;
    .submit-btn {
      margin-top: 20px;
      background-color: $main-app-color;
      color: #fff;
      width: 150px;
      height: 40px;
      border: none;
      border-radius: 5px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
