@import '../../scss/config';
@mixin sharedPadding {
  padding: 10px 14px;
}
$sharedCellWidth: 148px;

.selected-inovice-modal {
  .ant-modal-body {
    padding-top: 12px;
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    border-color: #888 !important;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    background-color: #eee !important;
    -webkit-print-color-adjust: exact;
    border-color: #888 !important;
  }
  .ant-table-thead > tr > th {
    background-color: transparent;
    color: #000;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    border-color: #888 !important;
  }
  .invoice-details-wrap td,
  .invoice-details-wrap th {
    padding: 10px !important;
    font-size: 14px;
    @include mobile {
      padding: 5px !important;
      font-size: 12px;
    }
  }
  .supp-buyer-wrap {
    .buyer-table {
      grid-template-columns: 1fr 1fr;
      @include mobile {
        grid-template-columns: 1fr;
      }
    }
  }

  .models-wrap {
    width: 100%;
    max-width: 110%;
    @include mobile {
      overflow: scroll;
    }
    .ant-spin-container {
      width: fit-content;
    }
  }
  .invoice-details-wrap {
    display: grid;
    gap: 16px;
    width: 100%;

    @include mobile {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 15px !important;
    }
    .supplier-details-wrap {
      @include mobile {
        width: 100% ;
      }
    }

    .inovice-nums-qrcode {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 22px;
      align-items: center;

      .nums-wrap {
        border: 1px solid rgba(#000, 0.2);
        border-radius: 4px;
      }
      .inovice-num {
        display: grid;
        grid-auto-flow: column;
        justify-content: start;
        grid-template-columns: $sharedCellWidth auto;
        font-size: 18px;
        border-bottom: 1px solid rgba(#000, 0.2);
        .num-title {
          border-left: 1px solid rgba(#000, 0.2);
          @include sharedPadding;
        }
        .num-itself {
          @include sharedPadding;
        }
      }

      .inovice-dates {
        display: grid;

        .date-wrap {
          display: grid;
          grid-auto-flow: column;
          justify-content: start;
          grid-template-columns: $sharedCellWidth auto;
          gap: 6px;
          font-size: 16px;
          border-bottom: 1px solid rgba(#000, 0.2);
          &:last-of-type {
            border-bottom: 0;
          }
          .date-title {
            border-left: 1px solid rgba(#000, 0.2);
            @include sharedPadding;
          }
          .date-value {
            @include sharedPadding;
          }
        }
      }

      .qr-code-wrap {
        display: grid;
        justify-content: end;
        margin-left: 20px;
        @include mobile {
          margin-left: 0px;
        }
        canvas {
          height: 92px !important;
          width: 92px !important;
        }
      }
    }
  }

  .models-title {
    color: rgba(0, 0, 0, 0.85);
  }
}

.selected-inovice-modal {
  .invoice-extra-prices-table {
    td {
      text-align: right;
      padding: 4px 16px;
    }
  }

  .circled-btn {
    margin-right: 18px;
    width: 38px;
    height: 38px;
    span {
      line-height: 40px;
    }
    svg {
      width: 22px;
      height: 22px;
    }
  }

  .ant-modal-body {
    padding: 0 !important;
  }
}
