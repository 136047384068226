.news-letter-page {
  .new-btn {
    margin-bottom: 32px;
    border-radius: 4px;
    padding: 1px 28px;
    height: 40px;
  }
}

.send-emails-message-form {
  .submit-btn {
    border-radius: 4px;
    padding: 1px 28px;
    height: 40px;
  }

  .editor-wrapper {
    .editor-title {
      color: #666;
      margin-bottom: 8px;
      font-size: 1rem;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .toolbarClassName {
      direction: ltr;
    }
    .wrapperClassName {
      margin-bottom: 12px;
      background-color: #f5f5f5;
      .editorClassName {
        padding: 0 18px;
        min-height: 180px;
      }
    }
  }
  .error-p {
    color: lighten(red, 10%);
    font-size: 14px;
    text-align: right;
  }
}
