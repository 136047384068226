.add-Admin-form {
  display: grid;
  .form-body {
    display: grid;
    grid-gap: 12px;
  }

  .select-option-label {
    margin-top: 8px;
    color: #666;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .editor-wrapper {
    .editor-title {
      color: #666;
      margin-bottom: 8px;
      font-size: 1rem;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .toolbarClassName {
      direction: ltr;
    }
    .wrapperClassName {
      margin-bottom: 12px;
      background-color: #f5f5f5;
      .editorClassName {
        padding: 0 18px;
        min-height: 180px;
      }
    }
  }

  .error-p {
    color: lighten(red, 10%);
    font-size: 14px;
    text-align: right;
  }
  .form-field-wrapper-div {
    display: grid;
    grid-template-columns: 80% 20%;
    div {
      text-align: center;
      button {
        width: 80%;
        height: 40px;
        color: #fff;
        background: #1890ff;
        border-color: #1890ff;
      }
    }
  }
  .upload-files-wrapper {
    .file-input-label {
      color: #666;
      margin-bottom: 8px;
      font-size: 1rem;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .dropzone-itself {
      min-height: 132px;
      background-color: #f5f5f5;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      border-width: 2px;
      border-radius: 2px;
      border-color: #eeeeee;
      border-style: dashed;
      color: #bdbdbd;
      cursor: pointer;
      outline: none;
      transition: border 0.24s ease-in-out;
    }
  }
  .imgs-preview-wrapper {
    display: flex;
    flex-wrap: wrap;

    .img-preview {
      display: grid;
      place-items: center;
      img {
        width: 200px;
      }
    }
  }

  .submit-btn {
    width: fit-content;
    height: 42px;
    padding: 8px 24px;
    border-radius: 5px;
    margin: auto;
  }
}

.add-Admin-modal {
  .ant-modal-header {
    background-color: #f5f5f5;
  }
  .ant-modal-title {
    font-size: 22px;
  }
}
.date-box {
  //  width: 100%;
  // padding: 6.5px 11px;
  height: 40px !important;
  font-size: 16px;
  position: relative;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  // width: fit-content;
    outline: none !important;

}
