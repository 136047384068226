@import '../../scss/config';

.customer-page {
  .button-filter-wrap {
    display: grid;
    grid-auto-flow: column;
    margin-bottom: 32px;
    grid-gap: 32px;
    justify-content: start;

    .customer-filter-select {
      .ant-select-selector {
        border-radius: 4px;
      }
    }

    .add-question-btn {
      cursor: pointer;
      padding: 12px 32px;
      height: 42px;
      border-radius: 5px;
      display: flex;
      align-items: center;

      .btn-text {
        margin-top: -4px;
      }
    }
  }

  .customer-table {
    .has-read-more {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 4px;
      justify-content: center;

      .read-more-book {
        display: grid;
        align-items: center;

        svg {
          width: 18px;
          height: 18px;
          color: $main-app-color;
          margin-top: 5px;
        }
      }
    }

    .delete-btn {
      color: rgb(230, 57, 57);
      background-color: rgb(246, 226, 226);
    }

    .edit-btn {
      color: #1fad7e;
      background-color: #e3f1e6;
    }

    .ant-table-content {
      @include custom-scrollbar;
    }

    .service-img {
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        object-position: center center;
        border-radius: 3px;
      }
    }

    .projects-link {
      text-decoration: underline;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .projects-btn {
        color: #ebb917;
        background-color: #f5efdd;
      }
    }

    tbody {
      background-color: #f5f5f5;

      &>tr>td {
        border-color: #ddd;
      }
    }
  }

    .otp-add-about-btn {
      cursor: pointer;
      padding: 12px 32px;
      height: 42px !important;
      border-radius: 5px;
      display: flex;
      align-items: center;
      margin-bottom: 32px;
    }
}

