@import '../../scss/config';
.opation-btn {
  display: inline-flex;
  div {
    margin: 0px 5px;
  }
  .delete-btn {
    color: rgb(230, 57, 57);
    background-color: rgb(246, 226, 226);
  }
  .edit-btn {
    color: #1fad7e;
    background-color: #e3f1e6;
  }
}

.image-project {
  display: grid;
  .add-img {
    grid-column: 1 / -1;
  }
  grid-template-columns: 2fr 2fr;
  grid-gap: 32px;
  .img-delete-wrap {
    background-color: #f5f5f5;
    padding: 8px;
    border-radius: 4px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: transparent;
    }
    img {
      width: 100%;
      height: auto;
      max-height: 360px;
      object-fit: cover;
      object-position: top center;
    }
    .delete-img-btn {
      cursor: pointer;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 52px !important;
      height: 52px !important;
      z-index: 3 !important;
      visibility: hidden;
      opacity: 0;
      span {
        font-size: 22px !important;
      }
      color: rgb(255, 255, 255) !important;
      background-color: rgb(248, 112, 112) !important;
    }
    &:hover {
      &::after {
        background-color: rgba(#000, 0.1);
      }

      .delete-img-btn {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.ant-table-content {
  @include custom-scrollbar;
}

.submit-btn {
  margin-top: 20px !important;
}
.table {
  margin-top: 20px;
}
