@import '../../scss/config';

.logo-link {
  display: grid;
  align-items: center;
  justify-items: center;
  margin: 10px 16px;
  img {
    height: 58px;
    max-width: 98%;
  }
}

.app-aside-menu {
  .statics_sub_menu {
    font-size: 18px;
  }

  .ant-menu-item {
    font-size: 18px;
    height: 50px !important;
    line-height: 50px !important;
    .anticon {
      svg {
        height: 18px;
        width: 18px;
      }
    }
  }
}

.site-layout .site-layout-background {
  background: #fff;
}

.app-site-layout {
  .ant-layout-header {
    display: flex;
    justify-content: space-between;
    height: 72px;
    .trigger-layout-btn {
      padding: 0 24px;
      font-size: 25px;
      line-height: 72px;
      cursor: pointer;
      transition: color 0.3s;
    }

    .trigger-layout-btn:hover {
      color: #1890ff;
    }

    .avatar-wrapper {
      padding: 0 24px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      display: flex;
      align-items: center;

      .profile-menu-btn {
        height: auto;
        border-radius: 5px;
        display: flex;
        align-items: center;
        .user-name {
          margin-bottom: 4px;
          margin-left: 6px;
          color: $main-app-color;
          font-weight: bold;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  // .ant-layout-sider{
  //   max-width: unset !important;
  //   min-width: unset !important;
  //   width: auto !important;
  //   flex: auto !important;
  // }
  // .ant-menu-title-content{
  //   display: none;
  // }
  .ant-layout-sider {
    position: fixed !important;
    z-index: 999;
    top: 58px;
    overflow-y: scroll;
    max-height: calc(100% - 58px) !important;
  }

  .app-site-layout .ant-layout-header {
    position: fixed;
    z-index: 999;
    width: 100%;
  }

  .ant-layout-sider::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }

  .ant-layout-sider::-webkit-scrollbar-track {
    border-radius: 10px !important;
    background-color: #d5d5d5;
  }

  .ant-layout-sider::-webkit-scrollbar-thumb {
    background-color: #5f8b9e8f;
    background-color: #b5b5b5;
    border-radius: 10px;
  }
  .ant-layout .ant-layout-content{
    padding-top: 72px;
  }
}
