.add-project-images-form {
  .previewFiles {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    .imgPreview {
      position: relative;
      border-radius: 2px;
      &::after {
        content: '';
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      img {
        width: 120px;
        height: 120px;
        border-radius: 2px;
        object-fit: cover;
        object-position: center center;
        background-color: #f5f5f5;
        border: 1px solid rgba(#000, 0.1);
      }

      .remove-file-btn {
        opacity: 0;
        visibility: hidden;
        cursor: pointer;
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgb(230, 57, 57);
        background-color: rgb(246, 226, 226);
        transition: none;
      }

      &:hover {
        .remove-file-btn {
          opacity: 1;
          visibility: visible;
        }
        &::after {
          background-color: rgba(#000, 0.2);
        }
      }
    }
  }
}
