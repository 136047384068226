@import '../../scss/config';

.merchantsStatsTable {
  margin-top: 40px;

  .ant-table-thead > tr > th {
    background-color: #595f6f;
    color: #fff;
  }
  .ant-table-tbody {
    background-color: #f5f5f5;
  }
  .ant-table-tbody > tr > td,
  .ant-table-wrapper-rtl .ant-table-thead > tr > th {
    text-align: center;
    border-color: #ddd;
  }

  .ant-table-content {
    @include custom-scrollbar;
  }
}
