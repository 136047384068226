
.export-pdf-btn{
    margin-right: 10px;
}
.toggle-user-status-btn {
    background-color: transparent;
    border: 0;
    outline: 0;
    border-radius: 10px;
    border: 1px solid #ddd;
    background-color: #ccc;
    padding: 4px 20px 8px 20px;
    color: #323232;
    cursor: pointer;
    &.active {
        background-color: #2a9d94;
        color: #fff;
    }
    &.not-active {
        background-color: #c6c6c6;
    }
  }