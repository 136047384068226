@import '../../scss/config';

.answers-modal-content {
  .person-data {
    background-color: #f5f5f5;
    padding: 16px;
    display: grid;
    grid-gap: 22px;
    border-radius: 8px;
    @include mediaLg {
      grid-template-columns: auto 1fr;
    }

    .person-name,
    .person-phone,
    .project-status,
    .project-cat {
      margin-bottom: 18px;
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #505050;
      gap: 8px;
    }

    .name-p,
    .phone-p {
      font-size: 22px;
      color: $main-app-color;
    }
    .status-tag,
    .cat-tag {
      padding: 8px 12px;
      border-radius: 4px;
      font-size: 16px;
    }
    .project-desc {
      @include mediaLg {
        border-right: 2px solid rgba(#000, 0.08);
        padding-right: 22px;
      }
      .desc-title {
        font-size: 18px;
        margin-bottom: 14px;
      }
      .desc-content {
        margin-right: 22px;
        p {
          margin-bottom: 8px;
        }
      }
    }
  }

  .answers-data {
    margin-top: 22px;
    display: grid;
    grid-gap: 22px;

    .answers-data-ul {
      .main-answer-li {
        background-color: #f5f5f5;
        border-radius: 8px;
        padding: 22px 24px;
        margin-bottom: 22px;
        &:last-of-type {
          margin-bottom: 0;
        }

        .question-wrap {
          .question-title {
            color: $main-app-color;
            font-size: 20px;
          }
          .question-chooses {
            margin: 18px 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 32px;

            .choose-item {
              display: flex;
              gap: 8px;
              font-size: 18px;
              color: #595f6f;
              align-items: center;
              .placeholder-span {
                background-color: #d1d1d1;
                width: 18px;
                height: 18px;
                border: 1px solid rgba(#000, 0.08);
                &.radio-span {
                  border-radius: 50%;
                }
                &.checkbox-span {
                  border-radius: 3px;
                }
              }
              .n-span {
                margin-bottom: 6px;
              }
            }
          }
        }
        .q-a-separator {
          display: grid;
          grid-template-columns: 1fr auto 1fr;
          grid-gap: 8px;
          align-items: center;
          color: #aaa;
          font-size: 15px;
          margin: 8px 0;
          .a-text {
            margin-top: -5px;
          }
          span {
            background-color: rgba(#000, 0.1);
            height: 1px;
            border-radius: 99px;
          }
        }

        .person-answers {
          img {
            max-height: 92px;
          }
          .answer-p {
            font-size: 18px;
            color: #595f6f;
          }
          .choose-spans {
            display: flex;
            gap: 18px;
          }
          .person-choose-span {
            background-color: #595f6f;
            color: #fff;
            padding: 8px 22px;
            padding-bottom: 12px;
            border-radius: 999px;
          }
        }
      }
    }
  }
}
