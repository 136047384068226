@import '../../scss/config';

.SystemErrors-container {
  .ant-spin-blur {
    color: red !important;
  }
  .add-about-btn {
    cursor: pointer;
    padding: 12px 32px;
    height: 42px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    .btn-text {
      margin-top: -4px;
    }
  }
  .SystemErrors-btn {
    color: #1fad7e !important;
    background-color: #e3f1e6;
  }

  .services-table {
    .delete-btn {
      color: rgb(230, 57, 57);
      background-color: rgb(246, 226, 226);
    }
    .edit-btn {
      color: #1fad7e !important;
      background-color: #e3f1e6;
    }

    .ant-table-content {
      @include custom-scrollbar;
    }
    .service-img {
      img {
        width: 100px;
        height: auto;
        object-fit: cover;
        object-position: center center;
        border-radius: 3px;
      }
    }

    .projects-link {
      text-decoration: underline;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      .projects-btn {
        color: #ebb917;
        background-color: #f5efdd;
      }
    }
    tbody {
      background-color: #f5f5f5;
      & > tr > td {
        border-color: #ddd;
      }
    }
  }
}
.SystemErrorsDetails {
  .SystemErrors_details_table {
    tr {
      & > td:first-of-type {
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        width: 10%;
      }
      & > td:last-of-type {
        @include mobile {
          word-break: break-all;
        }
      }
      td {
        padding: 7px 10px;
        font-size: 16px;
      }
    }
  }
}
