.update_password_form{
    label{
        font-size: 1rem !important;
        color: #666 !important;

    }
    .ant-form-item-control-input{
        height: 38px !important;
        .ant-form-item-control-input-content{
            height: 100%;
            .ant-input-affix-wrapper{
                height: 100%;
            }
        }
    }
}