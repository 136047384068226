.add-PackageDetailes-form {
  display: grid;
  .form-body {
    display: grid;
    grid-gap: 12px;
  }

  .select-option-label {
    margin-top: 8px;
    color: #666;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .editor-wrapper {
    .editor-title {
      color: #666;
      margin-bottom: 8px;
      font-size: 1rem;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .toolbarClassName {
      direction: ltr;
    }
    .wrapperClassName {
      margin-bottom: 12px;
      background-color: #f5f5f5;
      .editorClassName {
        padding: 0 18px;
        min-height: 180px;
      }
    }
  }

  .error-p {
    color: lighten(red, 10%);
    font-size: 14px;
    text-align: right;
  }

  .upload-files-wrapper {
    .file-input-label {
      color: #666;
      margin-bottom: 8px;
      font-size: 1rem;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .dropzone-itself {
      min-height: 132px;
      background-color: #f5f5f5;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      border-width: 2px;
      border-radius: 2px;
      border-color: #eeeeee;
      border-style: dashed;
      color: #bdbdbd;
      cursor: pointer;
      outline: none;
      transition: border 0.24s ease-in-out;
    }
  }
  .imgs-preview-wrapper {
    display: flex;
    flex-wrap: wrap;

    .img-preview {
      display: grid;
      place-items: center;
      img {
        width: 200px;
      }
    }
  }
  .input {
    border-radius: 2px;

    border: 1px solid #d9d9d9;
    height: 35px;
    width: 45%;
    margin: 2px;
  }
  .delete {
    width: 35px;
    margin-top: 2px 5px;
    height: 35px;
    border-radius: 50%;
    // border-radius: 2px;
    border: none;

    color: rgb(230, 57, 57);
    background-color: rgb(246, 226, 226);
  }
  .add {
    color: #fff;
    background: #1890ff;
    border-color: #1890ff;
    width: 244px;
    padding: 6px;
    border: none;
    border-radius: 7px;
  }
  .submit-btn {
    width: fit-content;
    height: 42px;
    padding: 8px 24px;
    border-radius: 5px;
    margin: auto;
  }
}

.add-Package-modal {
  .ant-modal-header {
    background-color: #f5f5f5;
  }
  .ant-modal-title {
    font-size: 22px;
  }
}

@media screen and (max-width:350px) {
  .add-PackageDetailes-form .add{
    width: fit-content;
  }
}