.SupscriptionStatsCharts {
  .no_data_div {
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
    letter-spacing: 1px;
  }
  .charts_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .chart_item {
      width: 50%;
    }
    .no_data_div {
      width: 50%;
      text-align: center;
      margin-bottom: 20px;
      font-size: 18px;
      letter-spacing: 1.5px;
    }
  }
  .subscription_count {
    position: absolute;
    top: 0;
    left: 0;
  }

  .details-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 10px;
    p {
      font-size: 18px;
      font-weight: 500;
      padding: 0px 20px;
      span {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .charts_container {
    .chart_item {
      width: 100%;
    }
  }
  .SupscriptionStatsCharts {
    .details-wrapper {
      align-items: center;
      margin-bottom: 20px;
    }
  }
}
