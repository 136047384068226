.add-service-form {
  display: grid;
  .form-body {
    display: grid;
    grid-gap: 12px;
  }

  .select-option-label {
    margin-top: 8px;
    color: #666;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .answer-type-wrap {
    .ant-radio-group {
      display: flex;
      gap: 18px;
      align-items: center;
    }
  }

  .question-types-wrap {
    .main-wrap-title {
      margin-bottom: 8px;
      color: #666;
      font-size: 1rem;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .labels-wrap {
      background-color: #f5f5f5;
      padding: 22px 16px;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 12px;
      border-radius: 5px;
      label {
        cursor: pointer;
        display: grid;
        border-radius: 4px;
        .ant-card {
          display: grid;
          grid-template-rows: auto 1fr;
          background-color: transparent;
          border-radius: 4px;
        }
        .ant-card-body {
          display: grid;
          place-items: center;
          border-radius: 0 0 4px 4px;
          padding: 8px 4px;
        }
        .ant-card-head {
          background-color: #304156;
          color: #fff;
          border-bottom: 0;
          margin-bottom: 0;
          border-radius: 4px 4px 0 0;
          text-align: center;
          transition: all 0.25s ease-out;
        }
        img {
          max-width: 98%;
          width: 72px;
        }
        background-color: #fff;
        transition: all 0.25s ease-out;
        &:hover,
        &.selected {
          background-color: #f0c568;
        }
        &.selected {
          .ant-card-head {
            color: #f5b938;
          }
        }
      }
    }
    &.error-wrap {
      .labels-wrap {
        background-color: lighten(red, 44%);
      }
    }
  }

  .dynamic-answers-wrap {
    background-color: #f5f5f5;
    padding: 32px 22px;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    grid-gap: 52px;

    .wrap-title {
      font-size: 20px;
      margin-bottom: 22px;
    }
    .add-asnswer-btn {
      // background-color: #1890ff;
      // color: #fff;
      margin-bottom: -22px;
      width: 62px;
      height: 62px;
      display: grid;
      place-items: center;

      svg {
        width: 28px;
        height: 28px;
      }
    }

    .dynamic-inputs-ul {
      .field-delete-li {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 12px;
      }
    }
  }

  .error-p {
    color: lighten(red, 10%);
    font-size: 14px;
    text-align: right;
  }

  .submit-btn {
    width: fit-content;
    height: 48px;
    padding: 12px 62px;
    border-radius: 5px;
    margin: auto;
    margin-top: 32px;
  }
}

.add-service-modal {
  .ant-modal-header {
    background-color: #f5f5f5;
  }
  .ant-modal-title {
    font-size: 22px;
  }
}
